import React from 'react';

const Online = () => (
  <>
    <div className="ring-container">
      <div className="ringring"></div>
      <div className="circle"></div>
    </div>
    Online Now
  </>
);

export default Online;
